<template>
  <div class="index-container">
    <dataFilter ref="dataFilter" :filter="filter">
      <el-button type="primary" @click="handleQuery()">查询</el-button>
      <el-button type="warning" @click="handleExport()">导出</el-button>
    </dataFilter>
    <div class="tab-content">
      <el-table border size="mini" :data="datas" style="width: 100%">
        <el-table-column label="基本信息" show-overflow-tooltip align="center">
          <el-table-column
            prop="Check.Code"
            label="测试编号"
            :width="180"
          >
          </el-table-column>
          <el-table-column label="客户姓名" :min-width="80">
            <template slot-scope="scope">
              <div>
                {{ scope.row.Check.Member.RealName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Check.Member.Mobile"
            label="手机号"
            :width="120"
          >
          </el-table-column>
          <el-table-column prop="Check.Member.Sex" label="性别" :width="60">
          </el-table-column>
          <el-table-column label="报告日期" :min-width="120">
            <template slot-scope="scope">
              <el-tag type="success">{{
                format.dateFormat(scope.row.Check.ReportDate)
              }}</el-tag>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="得分"
          prop="Score"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column label="步态参数" show-overflow-tooltip align="center">
          <el-table-column
            label="基础指标"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="配速"
              prop="Speed"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="弹性能量交换"
              prop="ElasticExchange"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="步频"
              prop="Striderate"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="跨步时间"
              prop="StrideTime"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="步幅"
              prop="Stridelength"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="垂直振幅"
              prop="Vdisp"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="触地时间"
              prop="Contacttime"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="角度" show-overflow-tooltip align="center">
            <el-table-column
              label="足部触地角度"
              prop="Footstrikeangle"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="前倾角度"
              prop="Spineangle"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="作用力" show-overflow-tooltip align="center">
            <el-table-column
              label="垂直作用力"
              prop="Vertforce"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="制动力"
              prop="Brakeforce"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="侧向作用力"
              prop="Latforce"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="评分" show-overflow-tooltip align="center">
            <el-table-column
              label="跑步经济性"
              prop="WorkTotalNorm"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="步态评分"
              prop="Striderating"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="总机械功"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="身体做功"
              prop="WorkExternalVlocityNorm"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="恢复做功"
              prop="WorkInternalNorm"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="重力做功"
              prop="WorkExternalGravityNorm"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table-column>

        <el-table-column label="力与力矩" show-overflow-tooltip align="center">
          <el-table-column
            label="关节压力指数"
            prop="Jointloading"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column label="膝关节" show-overflow-tooltip align="center">
            <el-table-column
              label="矢状面力矩左"
              prop="KneeMomentSagittalLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="矢状面力矩右"
              prop="KneeMomentSagittalRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="额状面力矩左"
              prop="KneemomentFrontalLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="额状面力矩右"
              prop="KneemomentFrontalRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="垂直作用力左"
              prop="KneeforceVerticalLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="垂直作用力右"
              prop="KneeforceVerticalRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="侧向作用力左"
              prop="KneeforceMediolateralLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="侧向作用力右"
              prop="KneeforceMediolateralRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="髋关节" show-overflow-tooltip align="center">
            <el-table-column
              label="矢状面力矩左"
              prop="HipMomentSagittalLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="矢状面力矩右"
              prop="HipMomentSagittalRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="额状面力矩左"
              prop="HipmomentFrontalLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="额状面力矩右"
              prop="HipmomentFrontalRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="垂直作用力左"
              prop="HipforceVerticalLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="垂直作用力右"
              prop="HipforceVerticalRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="侧向作用力左"
              prop="HipforceMediolateralLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="侧向作用力右"
              prop="HipforceMediolateralRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="步态分析-矢状面"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="触地时膝关节屈曲度"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="总"
              prop="KneeFlexLanding"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="左"
              prop="KneeflexlandingLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="KneeflexlandingRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column
            label="站立时最大膝关节屈曲度"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="总"
              prop="KneeFlexStanceMax"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="左"
              prop="KneeFlexStanceLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="KneeFlexStanceRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column
            label="摆动时最大膝关节屈曲度"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="总"
              prop="KneeflexswingMax"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="左"
              prop="KneeflexswingLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="KneeflexswingRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column
            label="股骨最大屈曲度"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="总"
              prop="ThighflexMax"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="左"
              prop="ThighflexLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="ThighflexRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>

          <el-table-column
            label="股骨最大伸展度"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="总"
              prop="ThighextMax"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="左"
              prop="ThighextLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="ThighextRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="触地时小腿角度"
            show-overflow-tooltip
            align="center"
          >
            <el-table-column
              label="左"
              prop="ShankangleLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="ShankangleRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="步态分析-额状面+三维动作评分"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column label="步态离散度"   align="center">
            <el-table-column
              label="左"
              prop="StepwidthLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="StepwidthRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="左膝内外翻角度"   align="center">
            <el-table-column
              label="左"
              prop="KneerotLeft"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="右"
              prop="KneerotRight"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="评分"   align="center">
            <el-table-column
              label="跑步性能得分"
              prop="Score1"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="跑步特征得分"
              prop="Score2"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="关节负荷得分"
              prop="Score3"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="三维动作总分"
              prop="Score"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="跑者类型"
              prop="Level"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :page-sizes="pageSizeList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>
    <script>
import dataFilter from "./components/dataFilter.vue";
import { exportData, getPageList } from "@/api/library/checkRunPosture.js";
export default {
  components: { dataFilter },
  data() {
    return {
      loading: false,
      total: 0,
      pageIndex: 1,
      pageSizeList: [10, 20, 30, 50, 80],
      pageSize: 10,
      datas: [],
      filter: {},
    };
  },
  methods: {
    handleExport() {
      this.loadInstance = this.$loading({
        lock: true,
        text: "正在导出...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.filter = this.$refs.dataFilter.getfilter();
      exportData(this.filter)
        .then((res) => {
          this.loadInstance.close();
          if (res) {
            window.open(this.baseUrl + res);
          }
        })
        .catch((ex) => {
          this.msg.errorMsg("导出出错：" + ex);
          this.loadInstance.close();
        });
    },
    handleQuery() {
      let tempfilter = this.$refs.dataFilter.getfilter();
      this.filter = tempfilter;
      this.getData();
    },
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      this.loading = true;
      getPageList(this.filter)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.datas = res.Items;
            this.total = res.Total || res.Items.length;
          }
        })
        .catch((ex) => {
          this.loading = false;
          this.total = 0;
          this.datas = [];
        });
    },
    pageSizeChange(val) {
      var pageSize = val;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
  },
  mounted() {
    this.handleQuery();
  },
};
</script>
    <style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>